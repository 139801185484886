@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaProExtraLight-Italic.woff2") format("woff2"),
    url("SofiaProExtraLight-Italic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Italic.woff2") format("woff2"),
    url("SofiaPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-BoldItalic.woff2") format("woff2"),
    url("SofiaPro-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-UltraLightItalic.woff2") format("woff2"),
    url("SofiaPro-UltraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Italic.woff2") format("woff2"),
    url("SofiaPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-LightItalic.woff2") format("woff2"),
    url("SofiaPro-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaProExtraLight-Italic.woff2") format("woff2"),
    url("SofiaProExtraLight-Italic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-MediumItalic.woff2") format("woff2"),
    url("SofiaPro-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-SemiBoldItalic.woff2") format("woff2"),
    url("SofiaPro-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-BlackItalic.woff2") format("woff2"),
    url("SofiaPro-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-BoldItalic.woff2") format("woff2"),
    url("SofiaPro-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-MediumItalic.woff2") format("woff2"),
    url("SofiaPro-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-LightItalic.woff2") format("woff2"),
    url("SofiaPro-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-BlackItalic.woff2") format("woff2"),
    url("SofiaPro-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-UltraLightItalic.woff2") format("woff2"),
    url("SofiaPro-UltraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-SemiBoldItalic.woff2") format("woff2"),
    url("SofiaPro-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-UltraLight.woff2") format("woff2"),
    url("SofiaPro-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Bold.woff2") format("woff2"),
    url("SofiaPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaProRegular.woff2") format("woff2"),
    url("SofiaProRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Medium.woff2") format("woff2"),
    url("SofiaPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Medium.woff2") format("woff2"),
    url("SofiaPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Light.woff2") format("woff2"),
    url("SofiaPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaProRegular.woff2") format("woff2"),
    url("SofiaProRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Black.woff2") format("woff2"),
    url("SofiaPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-ExtraLight.woff2") format("woff2"),
    url("SofiaPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Light.woff2") format("woff2"),
    url("SofiaPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-SemiBold.woff2") format("woff2"),
    url("SofiaPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Bold.woff2") format("woff2"),
    url("SofiaPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-ExtraLight.woff2") format("woff2"),
    url("SofiaPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-SemiBold.woff2") format("woff2"),
    url("SofiaPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-Black.woff2") format("woff2"),
    url("SofiaPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("SofiaPro-UltraLight.woff2") format("woff2"),
    url("SofiaPro-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

body {
  font-family: "Sofia Pro", serif;
}
